<template>
  <dispatch-form
    ref="dispatch-form"
    :is-create-form="true"
  />
</template>
<script>
import DispatchForm from '@/views/settings/fulfillment/dispatch/components/DispatchForm.vue'
import config from '../config'

export default {
  name: 'DispatchCreate',
  components: {
    DispatchForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['dispatch-form']) {
      this.$refs['dispatch-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  created() {
    const initialData = {
      is_active: false,
      name: '',
      payment_terms: [],
      payment_options: [],
    }
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_DISPATCH_FORM_CLONE`, initialData)
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
